import { useClientAccountId } from '@/composables/useClientAccountId'
import { useThFetch } from '@/composables/useThFetch'
import type { MetaQueryResponse } from '@/types/MetaQueryResponse'
import { formatUrl } from '@/utils/formatUrl'
import type { UseFetchOptions } from '@vueuse/core'

export function useBranchesMetaQuery(options?: UseFetchOptions) {
  const accountId = useClientAccountId()

  const url = () =>
    formatUrl(`/v1/branches/${accountId.value}/meta`, {
      deleted: false
    })

  return useThFetch(url, {
    afterFetch(ctx) {
      return {
        ...ctx,
        data: ctx.data.results?.[0]
      }
    },
    ...options
  }).json<MetaQueryResponse>()
}
