import type { Branch } from '@/types'
import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'

export type BranchesStoreState = ReturnType<typeof useBranchesStore>['$state']

export const useBranchesStore = defineStore('branches', () => {
  const branchesCount = ref(0)
  const selectedBranch = ref() as Ref<Branch>

  function setSelectedBranch(branch: Branch) {
    selectedBranch.value = branch
  }

  function clearSelectedBranch() {
    // This can be treated as non-nullable across the application, with the exception of
    // ChooseBranchView. Its protected by router guards
    // @ts-expect-error
    selectedBranch.value = undefined
  }

  return {
    selectedBranch,
    branchesCount,
    setSelectedBranch,
    clearSelectedBranch
  }
})
