interface DashboardContextEvent {
  type: 'dashboardContext'
  payload: {
    platform: 'unzer' | 'tillhub'
    branchId: string | null
  }
}

/**
 * Checks if the current application is embedded inside Unzer Dashboard.
 * This is accomplished by sending a message to the parent window ( if we are inside an iframe )
 * and waiting for a response with the application context.
 * The dashboard page which renders the iframe listens for the requestDashboardContext message and responds
 * with a new message, containing the application context.
 * It is currently being done this way as on production, the appointment calendar is rendered on a tillhub
 * subdomain, and the dashboard is rendered on the unzer subdomain - this is the recommended way to establish
 * communication when both applications are not on the same subdomain as browsers respond with CORS errors.
 */
export async function getDashboardContext() {
  const TIMEOUT = 1000

  if (isEmbeddedInIframe() === false) {
    return null
  }

  return new Promise<DashboardContextEvent['payload'] | null>((resolve, reject) => {
    let timeoutId: NodeJS.Timeout

    function onMessageReceived(event: MessageEvent) {
      if (event.data?.type === 'dashboardContext') {
        cleanUp()
        const data = event.data as DashboardContextEvent

        resolve(data.payload)
      }
    }

    function cleanUp() {
      window.removeEventListener('message', onMessageReceived)
      clearTimeout(timeoutId)
    }

    try {
      window.parent.postMessage({ type: 'requestDashboardContext' }, '*')

      window.addEventListener('message', onMessageReceived)

      timeoutId = setTimeout(() => {
        cleanUp()
        resolve(null)
      }, TIMEOUT)
    } catch (error) {
      cleanUp()
      reject(error)
    }
  })
}

function isEmbeddedInIframe() {
  try {
    return window.self !== window.top
  } catch (error) {
    // If an error occurred, its likely due to the fact that the parent window is not on the same origin
    // which means this app is inside an iframe
    return true
  }
}
