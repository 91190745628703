export class UnauthorizedError implements Error {
  public message = 'Unauthorized'
  public name = 'UnauthorizedError'
}

export class EmailNotFoundError implements Error {
  public name = 'EmailNotFoundError'
  public message = 'No user found with given email'
}

export class InvalidPasswordError implements Error {
  public name = 'InvalidPasswordError'
  public message = 'Invalid password'
}

export class InvalidRecaptchaError implements Error {
  public name = 'InvalidRecaptchaError'
  public message = 'Invalid recaptcha'
}
