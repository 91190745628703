<script setup lang="ts">
import { RouterView, useRouter } from 'vue-router'
import { useConfigurationQuery } from './api/useConfigurationQuery'
import { useStaffQuery } from './api/useStaffQuery'
import { computed, onErrorCaptured, onMounted, onUnmounted, ref, watch } from 'vue'
import { useConfigurationStore } from './stores/useConfigurationStore'
import { useStaffStore } from './stores/useStaffStore'
import { useAuthStore } from './stores/useAuthStore'
import { useQueryBatch } from '@/composables/useQueryBatch'
import NotificationsContainer from './plugins/notifications/NotificationsContainer.vue'
import WebAppHeader from './components/WebAppHeader.vue'
import LoadingOverlay from '@/plugins/loadingOverlay/LoadingOverlay.vue'
import { useLoadingOverlay } from '@/plugins/loadingOverlay/loadingOverlay'
import { useBranchesMetaQuery } from '@/api/useBranchesMetaQuery'
import { useBranchesStore } from '@/stores/useBranchesStore'
import ErrorView from './views/ErrorView.vue'
import { Env } from '@/env'
import { storeToRefs } from 'pinia'
import { useContextStore } from '@/stores/useContextStore'
import { LocalStorageKeys } from '@/constants'
import { useBranchQuery } from '@/api/useBranchQuery'
import type { BranchSelectedEvent } from '@/types/BranchSelectedEvent'

const authStore = useAuthStore()
const configurationStore = useConfigurationStore()
const staffStore = useStaffStore()
const branchesStore = useBranchesStore()
const router = useRouter()
const contextStore = useContextStore()

const { selectedBranch } = storeToRefs(branchesStore)

watch(selectedBranch, (branch) => {
  // When logging out, this will become null. We dont want to clear it
  // so that it is remembered if the user logs in with the same account
  if (branch) {
    localStorage.setItem(LocalStorageKeys.BranchId, branch.id)
  }
})

const layout = computed(() => router.currentRoute.value.meta.layout || 'default')

const configurationQuery = useConfigurationQuery({ immediate: false })
const staffQuery = useStaffQuery({ immediate: false })
const branchesMetaQuery = useBranchesMetaQuery({ immediate: false })

const resourceQueries = useQueryBatch([configurationQuery, staffQuery, branchesMetaQuery])

const isAuthenticating = ref(false)

watch(
  () => authStore.isInitialized,
  (isInitialized) => {
    isAuthenticating.value = isInitialized ? false : true
  },
  {
    immediate: true
  }
)

useLoadingOverlay(() => resourceQueries.isFetching.value || isAuthenticating.value)

const isInitialized = ref(false)

/**
 * The authNavigationGuard and branchNavigationGuard take care of the initial bootstrapping and
 * redirecting the user to the necessary routes so that he is authenticated and has selected a branch
 * in which he will be working in. After we have both of these, we need to fetch the rest of the global
 * state.
 */
watch(
  [() => authStore.user, selectedBranch],
  async ([user, selectedBranch]) => {
    if (!user) {
      // If there's no user, we want to reset this so that it global state is refetched once
      // the user logs back in.
      isInitialized.value = false

      return
    }

    if (!selectedBranch) {
      return
    }

    if (isInitialized.value) {
      return
    }

    await resourceQueries.execute()

    if (resourceQueries.isError.value) {
      showErrorView.value = true

      return
    }

    configurationStore.set(configurationQuery.data.value!)
    staffStore.set(staffQuery.data.value!)

    branchesStore.branchesCount = branchesMetaQuery.data?.value?.count ?? 0

    isInitialized.value = true
  },
  {
    immediate: true
  }
)

const showErrorView = ref(false)

if (Env.PROD) {
  // In production environments, if an uncaught error reaches the root component
  // we show the global error page. We dont want to do this locally because it would cause
  // the app to lose context which would make debugging harder.
  onErrorCaptured(() => {
    showErrorView.value = true
  })
}

async function changeSelectedBranch(branchId: string | null) {
  // User selected "All branches" in Dashboard
  if (branchId === null) {
    await router.push({ name: 'choose_branch' })

    return
  }

  const { data } = await useBranchQuery(branchId)

  if (!data.value) {
    await router.push({ name: 'choose_branch' })

    return
  }

  branchesStore.setSelectedBranch(data.value)

  const currentRoute = router.currentRoute.value

  if (currentRoute.name === 'choose_branch') {
    await router.push({ name: 'calendar' })
  }
}

/**
 * When the calendar application is embedded in Dashboard, we will receive message events
 * for certain interactions of the user inside Dashboard ( like branch selection changes ).
 */
async function onMessage(event: MessageEvent) {
  if (event.data && typeof event.data !== 'object') {
    return
  }

  if ('payload' in event.data === false) {
    return
  }

  if (event.data.type === 'branch:selected') {
    const data = event.data as BranchSelectedEvent

    await changeSelectedBranch(data.payload.branchId)
  }
}

onMounted(() => {
  window.addEventListener('message', onMessage)
})

onUnmounted(() => {
  window.removeEventListener('message', onMessage)
})
</script>

<template>
  <div class="relative h-full">
    <ErrorView v-if="showErrorView" />

    <RouterView v-if="layout === 'auth'" />

    <div v-else-if="isInitialized" class="h-full flex flex-col overflow-hidden">
      <WebAppHeader v-if="contextStore.isEmbedded === false" />

      <RouterView />
    </div>

    <LoadingOverlay />

    <NotificationsContainer />
  </div>
</template>
