import { getDashboardContext } from '@/auth/getDashboardContext'
import { usePosApi } from '@/plugins/posApi/usePosApi'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export type ContextStoreState = ReturnType<typeof useContextStore>['$state']

type Platform = 'pos' | 'unzer-dashboard' | 'tillhub-dashboard' | 'web'

export const useContextStore = defineStore('context', () => {
  const isInitialized = ref(false)
  const platform = ref<Platform>('web')
  const branchId = ref<string>()
  const posAPI = usePosApi()

  const isEmbedded = computed(() => platform.value !== 'web')

  function isEmbeddedIn(value: Platform) {
    return platform.value === value
  }

  async function init() {
    if (isInitialized.value) {
      return
    }

    isInitialized.value = true

    if (isEmbeddedInPOS()) {
      platform.value = 'pos'

      const getBranchResult = await posAPI.getActiveBranch()

      branchId.value = getBranchResult?.message_payload?.branch?.id || undefined

      return
    }

    const dashboardContext = await getDashboardContext()

    if (!dashboardContext) {
      platform.value = 'web'

      return
    }

    platform.value = dashboardContext.platform === 'unzer' ? 'unzer-dashboard' : 'tillhub-dashboard'
    branchId.value = dashboardContext.branchId ?? undefined
  }

  return {
    platform,
    isInitialized,
    isEmbedded,
    isEmbeddedIn,
    init,
    branchId
  }
})

function isEmbeddedInPOS() {
  return !!window.sendRequestToPos
}
