import { useAuthStore } from '@/stores/useAuthStore'
import { useBranchesStore } from '@/stores/useBranchesStore'
import { createRouter, createWebHistory } from 'vue-router'

export function createAppRouter() {
  return createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/',
        name: 'calendar',
        component: () => import('@/views/calendar/CalendarView.vue')
      },
      {
        path: '/auth/login',
        name: 'login',
        component: () => import('@/views/auth/LoginView.vue'),
        meta: {
          layout: 'auth'
        },
        beforeEnter() {
          const authStore = useAuthStore()

          if (authStore.user) {
            return { name: 'calendar' }
          }
        }
      },
      {
        path: '/auth/choose-branch',
        name: 'choose_branch',
        async beforeEnter(_, from) {
          const { selectedBranch } = useBranchesStore()

          // If we already have a selected branch and coming from the Login page,
          // we want to skip this route. The user can use the menu in the header to switch
          // branch
          if (selectedBranch) {
            if (!from.name || from.name === 'login') {
              return { name: 'calendar' }
            }
          }
        },
        component: () => import('@/views/auth/ChooseBranchView.vue'),
        meta: {
          layout: 'auth'
        }
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'not_found',
        component: () => import('@/views/NotFoundView.vue')
      }
    ]
  })
}
