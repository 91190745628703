import { useClientAccountId } from '@/composables/useClientAccountId'
import { useThFetch } from '@/composables/useThFetch'
import type { Branch } from '@/types'
import type { UseFetchOptions } from '@vueuse/core'

export function useBranchQuery(branchId?: string, options?: UseFetchOptions) {
  const accountId = useClientAccountId()

  return useThFetch(() => `/v0/branches/${accountId.value}/${branchId}`, {
    afterFetch(ctx) {
      return {
        ...ctx,
        data: ctx.data.results?.[0]
      }
    },
    ...options
  }).json<Branch>()
}
