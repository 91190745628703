
<script lang="ts" setup>
import { MenuItems } from '@headlessui/vue'
import { computed, onUnmounted, ref } from 'vue'
import { useFloating, flip, shift, offset, arrow } from '@floating-ui/vue'
import type { Placement } from '@floating-ui/vue'
import { useI18n } from '@/plugins/i18n'

export interface ThMenuItemsProps {
  placement?: Placement
  label?: string
}

const props = withDefaults(defineProps<ThMenuItemsProps>(), {
  placement: 'bottom',
  label: undefined
})

const { t } = useI18n()

const reference = ref<HTMLElement>()
const popover = ref<HTMLElement>()
const arrowRef = ref<HTMLElement>()

const { floatingStyles, middlewareData, placement } = useFloating(reference, popover, {
  transform: false,
  placement: props.placement,
  middleware: [
    flip(),
    shift({ padding: 20 }),
    offset({
      mainAxis: 18
    }),
    arrow({
      element: arrowRef
    })
  ]
})

const arrowStyle = computed(() => {
  const { x, y } = middlewareData.value.arrow || {}

  return {
    left: x != null ? `${x}px` : '',
    top: y != null ? `${y}px` : ''
  }
})

function onBeforeEnter(element: Element) {
  // HeadlessUI does not expose element refs, so we can't use a ref on the `MenuItems`
  // component and the button ref is not rendered in here, so we use the accessibility
  // attributes to get it.
  popover.value = element as HTMLElement
  const referenceId = element.getAttribute('aria-labelledby')
  reference.value = document.getElementById(referenceId!) as HTMLElement
}

function cleanUp() {
  reference.value = undefined
  popover.value = undefined
}

onUnmounted(cleanUp)
</script>

<template>
  <Teleport to="body">
    <Transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
      @before-enter="onBeforeEnter"
      @after-leave="cleanUp"
    >
      <MenuItems
        :style="floatingStyles"
        class="z-10 rounded-md bg-white shadow-md focus:outline-none min-w-[15.625rem]"
      >
        <div
          :style="arrowStyle"
          :class="{
            'bottom-full': placement.startsWith('bottom'),
            'top-full': placement.startsWith('top'),
            'left-full': placement.startsWith('left'),
            'right-full': placement.startsWith('right')
          }"
          ref="arrowRef"
          class="absolute h-0 w-0 border-x-[12px] border-x-transparent border-b-[14px] border-b-gray-100"
        ></div>

        <header class="px-4 text-center py-3.5 bg-gray-100 border-b border-gray-300 rounded-t-lg">
          <h5 class="text-md text-blue-900 font-semibold">
            {{ label || t('calendar.common.components.menu.title') }}
          </h5>
        </header>

        <div class="overflow-hidden rounded-[inherit]">
          <slot />
        </div>
      </MenuItems>
    </Transition>
  </Teleport>
</template>
