import { useClientAccountId } from '@/composables/useClientAccountId'
import { useThFetch } from '@/composables/useThFetch'
import type { Branch, PaginatedBackendResponse } from '@/types'
import { formatUrl } from '@/utils/formatUrl'
import type { UseFetchOptions } from '@vueuse/core'
import { toValue, type MaybeRefOrGetter } from 'vue'

export interface UseBranchesQueryParams {
  query?: string
  limit?: number
}

export function useBranchesQuery(
  rawParams?: MaybeRefOrGetter<UseBranchesQueryParams>,
  options: UseFetchOptions = {}
) {
  const accountId = useClientAccountId()

  function getUrl() {
    const { query, limit = 20 } = toValue(rawParams || {})

    return formatUrl(`/v1/branches/${accountId.value}`, {
      owner: 'self',
      deleted: false,
      q: query,
      limit: limit
    })
  }

  return useThFetch(getUrl, {
    ...options
  }).json<PaginatedBackendResponse<Branch>>()
}
