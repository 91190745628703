import { useDayjs } from '@/plugins/dayjs'
import type { AuthStrategy } from './AuthStrategy'
import { computed } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import { useThFetch } from '@/composables/useThFetch'
import { usePosApi } from '@/plugins/posApi/usePosApi'
import { EmailNotFoundError, InvalidPasswordError, InvalidRecaptchaError } from './errors'
import { useContextStore } from '@/stores/useContextStore'
import { LocalStorageKeys } from '@/constants'

export interface LoginData {
  email: string
  password: string
  recaptcha?: string
}

export interface DefaultAuthStrategy extends AuthStrategy {
  login: (loginData: LoginData) => Promise<void | Error>
}

export function makeDefaultAuthStrategy(): DefaultAuthStrategy {
  const dayjs = useDayjs()
  const posAPI = usePosApi()
  const contextStore = useContextStore()

  const sessionTokenExpiresAt = useLocalStorage<string | undefined>(
    LocalStorageKeys.SessionTokenExpiresAt,
    undefined
  )

  const sessionToken = useLocalStorage<string>(LocalStorageKeys.SessionToken, null, {
    flush: 'sync'
  })

  const isSessionTokenExpired = computed(() => {
    if (typeof sessionTokenExpiresAt.value === 'undefined') {
      return false
    }

    const expiresAt = dayjs(sessionTokenExpiresAt.value)

    if (!expiresAt.isValid()) {
      return true
    }

    return expiresAt.isBefore(dayjs(), 'milliseconds')
  })

  // @TODO Currently, this strategy determines authentication status via presence of the session token
  // This was done so that the logic resembles the Dashboard code, but is not especially meaningful.
  // Authentication status should be verified by the backend and not determined by client-side logic
  function isAuthenticated() {
    return isSessionTokenExpired.value === false && !!sessionToken.value
  }

  async function getToken() {
    return sessionToken.value
  }

  async function init() {
    // If the app is integrated inside the native application, try to get the authentication token
    // from it, so that we do not have to authenticate again via email/password
    if (contextStore.isEmbeddedIn('pos')) {
      const response = await posAPI.getSession()
      const token = response?.message_payload?.session?.token

      if (token) {
        // Session tokens obtained from the POS should not have client-side expiration
        sessionToken.value = token
        sessionTokenExpiresAt.value = undefined
      }
    }

    // Since the session tokens are kept in local storage, we do not need to do anything special
    // here. The `authStore` will attempt to fetch the user data if there is a token.
  }

  async function login(loginData: LoginData) {
    const { data, error } = await useThFetch('/v0/users/login', {
      onFetchError(ctx) {
        if (!ctx.response) {
          return ctx
        }

        const { response } = ctx
        const data: { msg: string; valid_password?: boolean } = ctx.data

        let error: Error = ctx.error

        if (response.status === 400) {
          error = new EmailNotFoundError()
        }

        if (response.status === 401) {
          if (data.valid_password === false) {
            error = new InvalidPasswordError()
          }

          if (data.msg?.includes('recaptcha')) {
            error = new InvalidRecaptchaError()
          }
        }

        return {
          ...ctx,
          error: error
        }
      }
    })
      .json<{ token: string }>()
      .post({
        email: loginData.email,
        password: loginData.password,
        recaptcha_token: loginData.recaptcha
      })

    if (data.value) {
      // When authenticating with email and password, the session is only valid for a day
      sessionToken.value = data.value.token
      sessionTokenExpiresAt.value = dayjs().add(1, 'day').toISOString()

      return
    }

    return error.value as Error
  }

  async function logout() {
    sessionToken.value = undefined
    sessionTokenExpiresAt.value = undefined
  }

  async function getLoginUrl() {
    return {
      name: 'login'
    }
  }

  return {
    getLoginUrl,
    name: 'default',
    login,
    logout,
    getToken,
    isAuthenticated,
    init
  }
}
