import { useBranchesQuery } from '@/api/useBranchesQuery'
import { useBranchQuery } from '@/api/useBranchQuery'
import { LocalStorageKeys } from '@/constants'
import { useAuthStore } from '@/stores/useAuthStore'
import { useBranchesStore } from '@/stores/useBranchesStore'
import { useContextStore } from '@/stores/useContextStore'
import type { NavigationGuard, RouteLocationRaw } from 'vue-router'

export const branchNavigationGuard: NavigationGuard = async (to) => {
  const { user } = useAuthStore()
  const contextStore = useContextStore()
  const branchesStore = useBranchesStore()
  const { selectedBranch } = branchesStore

  // If we don't have a user or we already have a selected branch, there's nothing to do
  if (!user || selectedBranch) {
    return
  }

  let redirectRoute: RouteLocationRaw | undefined

  // In the event of failing to guess or fetch the selected branch, the user has to be redirected to the
  // Choose Branch view. We only need to return that if the user is not already being redirected there
  // to avoid infinite redirects.
  if (to.name === 'choose_branch') {
    redirectRoute = undefined
  } else {
    redirectRoute = {
      name: 'choose_branch'
    }
  }

  const { data, error } = await useBranchesQuery({ limit: 2 })

  if (error.value) {
    // This query is used to guess the branch, if it failed, user needs to specify manually.
    return redirectRoute
  }

  const branches = data.value!.results

  let branchId: string | null = null

  if (branches.length === 1) {
    // If there's a single branch in the account, we automatically select it
    branchId = branches[0].id
  } else if (contextStore.branchId) {
    // Check if we have a selected branch from the parent application ( Dashboard or POS )
    // if so, select it
    branchId = contextStore.branchId
  } else {
    branchId = localStorage.getItem(LocalStorageKeys.BranchId)
  }

  if (!branchId) {
    return redirectRoute
  }

  const { data: branch } = await useBranchQuery(branchId)

  if (!branch.value) {
    return redirectRoute
  }

  branchesStore.setSelectedBranch(branch.value)
}
