import type { UseFetchReturn } from '@vueuse/core'
import { computed } from 'vue'

/**
 * Accepts an array of queries and allows you to monitor them in bulk
 * isError - at least one of the queries has failed
 * isFetching - at least one of the queries is fetching
 */
export function useQueryBatch(queries: UseFetchReturn<any>[]) {
  // When an array of queries is passed with { immediate: true }, useFetch does not execute them immediately
  // which can lead to errors in vue templates, because both isError and isFetching are set to false, but there is no data yet.
  // isFinished will be set to false initially, when both isFetching and isError are set to false.
  const isFinished = computed(() => queries.every((query) => query.isFinished.value))

  const isFetching = computed(() => queries.some((query) => query.isFetching.value))
  const isError = computed(() => queries.some((query) => !!query.error.value))

  async function execute(throwOnFailed?: boolean) {
    await Promise.all(queries.map((query) => query.execute(throwOnFailed)))
  }

  return { isFetching, isError, isFinished, execute }
}
