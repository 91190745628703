import type { Plugin } from 'vue'
import { createAppRouter } from '@/router'
import { authNavigationGuard } from '@/router/guards/authNavigationGuard'
import { branchNavigationGuard } from '@/router/guards/branchNavigationGuard'

export function createRouter(): Plugin {
  return {
    install(app) {
      const router = createAppRouter()

      router.beforeEach(authNavigationGuard)
      router.beforeEach(branchNavigationGuard)

      app.use(router)
    }
  }
}
