import Keycloak, { type KeycloakConfig, type KeycloakLoginOptions } from 'keycloak-js'
import type { AuthStrategy } from './AuthStrategy'
import { Env } from '@/env'
import { useSentry } from '@/plugins/sentry'

// Refreshes the token if its validity expires in < value seconds
const MIN_ACCESS_TOKEN_VALIDITY_SECONDS = 6000

export interface KeycloakAuthStrategy extends AuthStrategy {
  login: () => Promise<void>
}

export function makeKeycloakAuthStrategy(): KeycloakAuthStrategy {
  const sentry = useSentry()
  let keycloak: Keycloak

  function isAuthenticated() {
    return keycloak.authenticated ?? false
  }

  // Used to prevent multiple concurrent refresh token requests
  let refreshTokenPromise: Promise<any> | null = null

  async function refreshToken() {
    if (refreshTokenPromise) {
      return refreshTokenPromise
    }

    refreshTokenPromise = keycloak.updateToken(MIN_ACCESS_TOKEN_VALIDITY_SECONDS)

    refreshTokenPromise.finally(() => {
      refreshTokenPromise = null
    })

    return refreshTokenPromise
  }

  async function getToken() {
    try {
      await refreshToken()
    } catch (err) {
      sentry.captureException(err)

      return null
    }

    return keycloak.token ?? null
  }

  async function init() {
    if (keycloak) {
      return
    }

    const config: KeycloakConfig = {
      url: Env.VITE_VUE_APP_KEYCLOAK_URL,
      realm: Env.VITE_VUE_APP_KEYCLOAK_REALM,
      clientId: Env.VITE_VUE_APP_KEYCLOAK_CLIENT
    }

    keycloak = new Keycloak(config)

    /**
     * @docs https://www.keycloak.org/securing-apps/javascript-adapter
     */
    await keycloak.init({
      flow: 'standard',
      // Disables the automatic background checks for the authentication status of the user
      // ( in case he was logged out in another tab )
      checkLoginIframe: false,
      // With the `check-sso` property, Keycloak will not automatically redirect the user to the login page if he is not authenticated.
      // We do this manually from the `authNavigationGuard.ts` middleware.
      onLoad: 'check-sso',
      // There's an empty html page at this url, which is fetched inside an iframe
      // to avoid redirecting the user back and forth
      silentCheckSsoRedirectUri: `${window.location.origin}/check-sso.html`
    })
  }

  function getKeycloakLoginOptions(): KeycloakLoginOptions {
    return {
      locale: 'de',
      redirectUri: window.location.origin
    }
  }

  /**
   * Logging in via this strategy is done on an external page, after which the user
   * gets redirected back to this application.
   */
  async function login() {
    const options = getKeycloakLoginOptions()

    await keycloak.login(options)
  }

  /**
   * Logging out via this strategy is done on an external page, after which the user
   * gets redirected back to this application.
   */
  async function logout() {
    await keycloak.logout({
      redirectUri: window.location.origin
    })
  }

  async function getLoginUrl() {
    const options = getKeycloakLoginOptions()

    return await keycloak.createLoginUrl(options)
  }

  return {
    getLoginUrl,
    name: 'keycloak',
    login,
    logout,
    getToken,
    isAuthenticated,
    init
  }
}
